<template>
  <div class="modal-back">
    <form action="#" id="deleteUser">
      <label class="delete-text">
        {{ title }}
      </label>
      <button type="submit" class="red-button submit-sms">Удалить</button>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    title: String
  }
}
</script>
