<template>
  <div class="payments-table">
      <div class="payments-table">
        <VTable class="numbers-table"
                :data="payments"
                :pageSize="100"
                v-model:currentPage="currentPage"
                @totalPagesChanged="totalPages = $event"
        >
          <template #head>
            <tr>
              <VTh sortKey="number" >Номер</VTh>
              <VTh sortKey="balance" >Баланс</VTh>
              <VTh sortKey="payment_value" >Сумма пополнения</VTh>
              <VTh :customSort="dateSort" defaultSort="desc">Дата и время</VTh>
            </tr>
          </template>
          <template #body="{rows}">
            <tr v-for="row in rows" :key="row.id">
              <td>{{ row.number }}</td>
              <td>{{ row.balance }} ₽</td>
              <td>{{ row.payment_value }} ₽</td>
              <td>{{ formatDate(new Date(row.date)) }}</td>
            </tr>
          </template>
        </VTable>
        <VTPagination
            v-model:currentPage="currentPage"
            :total-pages="totalPages"
            :boundary-links="true"
        />
      </div>
    <div> {{ info }} </div>
  </div>
</template>

<script>
import {default as axios} from '@/services/axios.service';
export default {
  name: 'PaymentsTable',
  methods: {
    dateSort(a, b, sortOrder) {
      let date1 = new Date(a.date).getTime();
      let date2 = new Date(b.date).getTime();

      if (sortOrder === 1) {
        return date1 - date2
      } else if (sortOrder === -1) {
        return date2 - date1
      } else {
        return 0
      }
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    formatDate(date) {
      return (
          [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
          ].join('-') +
          ' ' +
          [
            this.padTo2Digits(date.getHours()),
            this.padTo2Digits(date.getMinutes()),
            this.padTo2Digits(date.getSeconds()),
          ].join(':')
      );
    }
  },
  data() {
    return {
      payments: [],
      info: null,
      currentPage: 1,
      totalPages: 1
    }
  },
  mounted() {
    axios
    .get('api/payment/')
    .then(response => (this.payments = response.data))
  }
}
</script>
