<template>
  <div class="settings">
    <div class="add-block">
      <div id="settings">
        <label>Чек баланса каждые (минут):
          <input type="number" v-model="settings.time_check">
        </label>
        <label>MIN баланс (₽):
          <input type="number"  v-model="settings.min_balance">
        </label>
        <label>Popolni Логин (Баланс: {{settings.poplni_balance}} ₽):
          <input type="text" v-model="settings.poplni_login">
        </label>
        <label>Popolni Пароль:
          <input type="text" v-model="settings.poplni_password">
        </label>
        <label>CaptchaGuru Token (Баланс: {{ settings.captcha_balance }} ₽):
          <input type="text" v-model="settings.captcha_guru">
        </label>
        <button @click="update" class="red-button save-button">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import {default as axios} from '@/services/axios.service';

  export default {
    name: 'SettingsPage',
    data() {
        return {
          settings: {}
      }
    },
    methods: {
      update() {
        axios.patch('api/setting/1/', this.settings).then(response => {
          this.settings = response.data
        })
      }
    },
    mounted() {
      axios.get('api/setting/')
      .then(response => {
        this.settings = response.data[0]
      })
    }
  }
</script>
