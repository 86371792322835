export const Role = {
  AddUser: {id: 13, permissions: [13, 15, 16]},
  Numbers: {id: 28, permissions: [26,27,28]},
  DeletedNumbes: {id: 32, permissions: [29,31,30,32]},
  AddNumber: {id: 25, permissions: [25,45,46,47,48]},
  Settings: {id: 44, permissions: [41,42,44]},
  Payments: {id: 36, permissions: [36]},
  Null: {id: 0, permissions: []}
}

export const RoleNames = {
  AddUser: {id: 13, name: 'Пользователи'},
  Numbers: {id: 28, name: 'Номера'},
  AddNumber: {id: 25, name: 'Добавление номеров'},
  Settings: {id: 44, name: 'Настройки'},
  Payments: {id: 36, name: 'Платежи'},
}
