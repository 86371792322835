<template>
  <div class="add-user">
    <div class="add-block">
      <form action="#" id="sms">
        <label>Логин
          <input v-model="login" type="text" id="add-login" required>
        </label>
        <label>Пароль
          <input v-model="password" type="password"  required>
        </label>
        <label class="check-label">Показывать разделы
          <br>
          <span><input v-model="isNumbersPermission" type="checkbox" id="numbers" name="numbers">
          <label for="numbers">Номера</label></span>
          <span><input v-model="isAddNumberPermission" type="checkbox" id="add-numbers" name="add-numbers">
          <label for="add-numbers">Добавить номер</label></span>
           <span><input v-model="isSettingsPermission" type="checkbox" id="settings" name="settings">
          <label for="settings">Настройки</label></span>
           <span><input v-model="isPaymentsPermission" type="checkbox" id="payments" name="payments">
          <label for="payments">Платежи</label></span>
           <span><input v-model="isAddUserPermission" type="checkbox" id="add-users" name="add-users">
          <label for="add-users">Добавить пользователя</label></span>
        </label>
        <button @click="handleAddUser" type="button" class="red-button save-button">Сохранить</button>
      </form>
    </div>
  </div>
</template>
<script>
import {Role} from '@/helpers/role';
import {default as axios} from '@/services/axios.service';

export default {
  name: 'AddUser',
  data() {
    return {
      isNumbersPermission: false,
      isAddNumberPermission: false,
      isSettingsPermission: false,
      isPaymentsPermission: false,
      isAddUserPermission: false,
      login: null,
      password: null
    }
  },
  methods: {
    handleAddUser() {
      let permissions = []

      if (this.isNumbersPermission){
        permissions = permissions.concat(Role.Numbers.permissions)
        permissions = permissions.concat(Role.DeletedNumbes.permissions)
      }
      if (this.isAddNumberPermission){permissions = permissions.concat(Role.AddNumber.permissions)}
      if (this.isSettingsPermission){permissions = permissions.concat(Role.Settings.permissions)}
      if (this.isPaymentsPermission){permissions = permissions.concat(Role.Payments.permissions)}
      if (this.isAddUserPermission){permissions = permissions.concat(Role.AddUser.permissions)}

      axios.post('api/user/', {
        username: this.login,
        password: this.password,
        user_permissions: permissions
      }).then(response => {
        console.log('User successfully added')
        this.$router.push('/users')
      })
    }
  },
}
</script>
