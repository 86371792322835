<template>
  <div v-if="loggedIn" class="menu">
    <span class="menu-text">Разделы</span>
    <ul>
       <router-link to="/add-number"><li @click="hideMobileMenu">Добавить номер</li></router-link>
       <router-link to="/numbers"><li @click="hideMobileMenu">Номера</li></router-link>
       <router-link to="/deleted-numbers"><li @click="hideMobileMenu">Удаленные номера</li></router-link>
       <router-link to="/payments"><li @click="hideMobileMenu">Платежи</li></router-link>
       <router-link to="/settings"><li @click="hideMobileMenu">Настройки</li></router-link>
       <router-link to="/users"><li @click="hideMobileMenu">Пользователи</li></router-link>
       <router-link to="/add-user"><li @click="hideMobileMenu">Добавить пользователя</li></router-link>
       <router-link to="/logs"><li @click="hideMobileMenu">Логи</li></router-link>
       <li><button @click="handleLogOut" class="red-button" style="width: 100%">Выход</button></li>
    </ul>
    <span class="hide-menu" @click="hideMenu">Скрыть меню</span>
  </div>
  <router-view />
</template>

<script>
export default {
  name: 'MenuMTC',
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
  },
  methods: {
    hideMenu(){
      document.querySelector('.menu').classList.add('hidden')
      document.querySelector('.container').classList.add('grid-block')
      document.querySelector('.show-menu').classList.remove('hidden')
    },
    handleLogOut() {
      this.$store.dispatch("logout")
      this.$router.push('/login')
    },
    hideMobileMenu() {
      if (window.innerWidth < 475) {
        document.querySelector('.menu').classList.add('hidden')
        document.querySelector('.container').classList.add('grid-block')
        document.querySelector('.show-menu').classList.remove('hidden')
      }
    }
  }
}
</script>
