<template>
  <div class="deleted-numbers">
    <VTable class="numbers-table"
            :data="deleted_numbers"
            :pageSize="10"
            v-model:currentPage="currentPage"
            @totalPagesChanged="totalPages = $event"
    >
      <template #head>
        <tr>
          <VTh :customSort="dateSort">Дата добавления</VTh>
          <VTh :customSort="dateSort2" defaultSort="desc">Дата удаления</VTh>
          <VTh sortKey="number" >Номер</VTh>
          <VTh sortKey="password" >Пароль</VTh>
          <VTh sortKey="comment" >Комментарий</VTh>
        </tr>
      </template>
      <template #body="{rows}">
        <tr v-for="row in rows" :key="row.id">
          <td>{{ formatDate(new Date(row.date_create)) }}</td>
          <td>{{ formatDate(new Date(row.date_del)) }}</td>
          <td>{{ row.number }}</td>

          <td>{{ row.password }}</td>
          <td class="flex-area">
            <textarea v-model="row.comment" class="number-area commentary-area" readonly></textarea>
            <button class="edit-button" @click="editElement()"><img class="edit-2" src="/svg/edit.svg" alt=""></button>
            <button class="red-button save-button hidden" @click="saveElement(row.id)">Сохранить</button>
          </td>
        </tr>
      </template>
    </VTable>

    <VTPagination
        v-model:currentPage="currentPage"
        :total-pages="totalPages"
        :boundary-links="true"
    />
  </div>
</template>

<script>
import {default as axios} from '@/services/axios.service';

export default {
  name: 'DeletedNumbers',
  methods: {
    dateSort(a, b, sortOrder) {
      let date1 = new Date(a.date_create).getTime();
      let date2 = new Date(b.date_create).getTime();

      if (sortOrder === 1) {
        return date1 - date2
      } else if (sortOrder === -1) {
        return date2 - date1
      } else {
        return 0
      }
    },
    dateSort2(a, b, sortOrder) {
      let date1 = new Date(a.date_del).getTime();
      let date2 = new Date(b.date_del).getTime();

      if (sortOrder === 1) {
        return date1 - date2
      } else if (sortOrder === -1) {
        return date2 - date1
      } else {
        return 0
      }
    },
    editElement() {
      document.addEventListener('click',function (e) {
        const clickTarget = e.target;
        const clickParent = clickTarget.parentElement;
        const siblingEl = clickParent.previousElementSibling;
        const buttonEl = clickParent.nextElementSibling

        if (e.target.classList.contains('edit-2')){
        siblingEl.readOnly = false;
          buttonEl.classList.remove('hidden')
      }
      })
    },
    saveElement(id) {
      this.updateNumber(id)
      document.addEventListener('click', e => {
        const getButton = e.target
        if(getButton.classList.contains('save-button')) {
          const getParent = getButton.parentElement;
          const childrenArea = getParent.children

          childrenArea.item(0).readOnly = true;
          getButton.classList.add('hidden');
        }
      })
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },

    formatDate(date) {
      return (
          [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
          ].join('-') +
          ' ' +
          [
            this.padTo2Digits(date.getHours()),
            this.padTo2Digits(date.getMinutes()),
            this.padTo2Digits(date.getSeconds()),
          ].join(':')
      );
    },
    updateNumber(id) {
      let numberData = this.deleted_numbers.find(x => x.id === id)
      axios.get(`api/change-comment/?number=${numberData.number}&comment=${numberData.comment}`, {
        comment: numberData.comment
      })
      .then(response => {
        console.log('success')
      })
      .catch(error => {
        console.log('error')
      })
    }
  },
  data() {
    return {
      deleted_numbers: [],
      currentPage: 1,
      totalPages: ''
    }
  },
  mounted() {
    axios
    .get('api/del/')
    .then(response => (this.deleted_numbers = response.data))
  }
}
</script>
