<template>
  <div class="add-number">
    <div class="add-block">
      <div class="tabs">
        <ul>
          <li class="active sms_tab" @click="selectTab">По СМС</li>
          <li class="pass_tab" @click="selectTab">По паролю</li>
        </ul>
      </div>
      <form action="#" id="sms">
        <label>Номер
          <input @click="hideNotice" v-model="sms_phone" type="text" id="add-number" required v-maska="['+7 (###) ###-##-##', '+7 (___) ___-__-__']" placeholder="+7 (___) ___-__-__">
        </label>
        <p class="number-notice hidden">Введите правильный номер</p>
        <button type="button" class="red-button" @click="openSMS()">Отправить СМС</button>
      </form>
      <form action="#" id="pass" class="hidden">
        <label>Номер
          <input @click="hideNoticePass" v-model="phone" type="text" id="add-number-by-pass" required v-maska="['+7 (###) ###-##-##', '+7 (___) ___-__-__']" placeholder="+7 (___) ___-__-__">
        </label>
        <p class="number-notice pass-notice hidden">Введите правильный номер</p>
        <label>Пароль
          <input type="password" v-model="password" required>
        </label>
        <button type="button" class="red-button" @click="openSMSbyPass()">Сохранить</button>
      </form>
    </div>
  </div>
</template>
<script>
import {openModal} from "jenesius-vue-modal"
import WidgeTestModal from "../popups/SendSms.vue";
import WidgeSecondModal from "../popups/SendSecondSms.vue";
import {default as axios} from '@/services/axios.service';

export default {
  name: 'AddNumber',
  data() {
    return {
      sms_phone: null,
      phone: null,
      password: null
    }
  },
  methods: {
     openSMS() {
      const notice = document.querySelector('.number-notice');
      if(document.getElementById("add-number").value.length === 0)
      {
        notice.classList.remove('hidden')
      } else if(document.getElementById("add-number").value.length !== 18)
      {
        notice.classList.remove('hidden')
      } else {
        axios.get(`api/get-code/?number=${this.sms_phone}`)
        .then(async response => {
          const smsModal = await openModal(WidgeTestModal, {
            title: "Code"
          })

          smsModal.on('submit', v => {
            v.preventDefault()
            this.addNumberWithSms(smsModal.instance.sms_code).then(() => {smsModal.close()})
          })
        }).catch(error => {
          console.log(error)
        })
      }
    },
    openSMSbyPass() {
      const notice = document.querySelector('.pass-notice');
      if(document.getElementById("add-number-by-pass").value.length === 0)
      {
        notice.classList.remove('hidden')
      } else if(document.getElementById("add-number-by-pass").value.length !== 18)
      {
        notice.classList.remove('hidden')
      } else {
        this.addNumberWithoutSms()
      }
    },
    hideNotice() {
      const notice = document.querySelector('.number-notice');
      if (notice.classList.contains('hidden')) {
        return 0;
      } else {
        notice.classList.add('hidden')
      }
    },
    hideNoticePass() {
      const notice = document.querySelector('.pass-notice');
      if (notice.classList.contains('hidden')) {
        return 0;
      } else {
        notice.classList.add('hidden')
      }
    },
    selectTab() {
      document.addEventListener('click', e => {
        if (e.target.classList.contains('sms_tab')){
          e.target.classList.add('active')
          document.querySelector('.pass_tab').classList.remove('active')
          document.getElementById('sms').classList.remove('hidden')
          document.getElementById('pass').classList.add('hidden')
          e.preventDefault()
        } else if (e.target.classList.contains('pass_tab')) {
          e.target.classList.add('active')
          document.querySelector('.sms_tab').classList.remove('active')
          document.getElementById('pass').classList.remove('hidden')
          document.getElementById('sms').classList.add('hidden')
          e.preventDefault()
        }
      })
    },

    addNumberWithoutSms() {
      axios.post('api/number/', {
        number: this.phone.replace(/[^0-9]/g,'').substring(0,11),
        password: this.password
      }).then(() => {
        this.$router.push({path: '/numbers'});
      }).catch(error => {
        //
      })
    },
    addNumberWithSms(code) {
       this.sms_phone = this.sms_phone.replace('+', '')
       return axios.get(`api/send-code/?number=${this.sms_phone}&code=${code}`)
      .then(async () => {
         const smsSecontModal = await openModal(WidgeSecondModal, {
           title: "Code"
         })
         smsSecontModal.on('submit', v => {
           v.preventDefault()
           axios.get(`api/send-second-code/?number=${this.sms_phone}&code=${smsSecontModal.instance.sms_code}`).then(() => {
             smsSecontModal.close()
             this.$router.push({path: '/numbers'});
           })
         })
       })
      .catch(error => {
        //
       })
    }
  },
}
</script>
