<template>
  <div class="add-number" style="display: flex; align-items: center; justify-content: center">
    <div class="add-block" style="margin-top: 0">
      <label>Логин<input v-on:keyup.enter="onEnter" required v-model="login"></label>
    <label>Пароль<input v-on:keyup.enter="onEnter" required type="password" v-model="password"></label>
<!--    <h1 v-if="failed">ACHTUNG!! LOGIN FAILED</h1>-->
    <button @click="loginMethod" class="red-button" style="width: 100%">Войти</button>
  </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      login: null,
      password: null,
      failed: false
    }
  },
  methods: {
    loginMethod() {
      let user = {
        login: this.login,
        password: this.password
      }
      this.$store.dispatch("login", user).then(
          () => {
            this.failed = false
            this.$router.push("/");
          }).catch(
              () => {
                this.failed = true
              }
      )
    },
    onEnter() {
      let user = {
        login: this.login,
        password: this.password
      }
      this.$store.dispatch("login", user).then(
          () => {
            this.failed = false
            this.$router.push("/");
          }).catch(
          () => {
            this.failed = true
          }
      )
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/numbers");
    }
  },
};
</script>

<style scoped>

</style>
