<template>
  <div class="logs-table">
    <div class="logs-table">
      <input placeholder="Поиск по задаче..." v-model="searchTerm"/>
      <VueTableLite
          :messages="this.table.messages"
          :is-loading="this.table.isLoading"
          :columns="this.table.columns"
          :rows="this.table.rows"
          :total="this.table.totalRecordCount"
          :page-size="100"
          :page-options="[{ value: 100, text: 100}]"
          @do-search="doSearch"
          @is-finished="tableLoadingFinish"
      />
    </div>
  </div>
</template>

<script>
import {default as axios} from '@/services/axios.service';
import VueTableLite from 'vue3-table-lite';

export default {
  name: 'LogsPage',
  components: { VueTableLite },
  methods: {
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return (
          [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
          ].join('-') +
          ' ' +
          [
            this.padTo2Digits(date.getHours()),
            this.padTo2Digits(date.getMinutes()),
            this.padTo2Digits(date.getSeconds()),
          ].join(':')
      );
    },
    doSearch (offset, limit, order, sort) {
      this.table.isLoading = true;

      let page = 1;
      if (offset && (offset / limit) >= 1) {
        page = (offset / limit) + 1;
      }

      //let url = `http://89.22.235.121/api/log/?page=${page}&search=${this.searchTerm}`;
      //let url = `http://178.20.47.126/api/log/?page=${page}&search=${this.searchTerm}`;
      let url = `http://93.183.92.191/api/log/?page=${page}&search=${this.searchTerm}`;
      axios.get(url).then((response) => {
        this.table.rows = response.data.results;
        this.table.totalRecordCount = response.data.count;
      });

      this.table.sortable.order = order;
      this.table.sortable.sort = sort;
    },
    tableLoadingFinish(elements) {
      console.log(1, this.table.sortable.order, this.table.sortable.sort, this.table.isLoading)
      this.table.isLoading = false;
    },
  },
  data() {
    return {
      searchTerm: '',
      table: {
        isLoading: false,
        columns: [
          {
            label: 'Задача',
            field: 'task',
            width: '10%',
          },
          {
            label: 'Дата',
            field: 'date',
            width: '15%',
            display: row => this.formatDate(new Date(row.date))
          },
          {
            label: 'Статус',
            field: 'status',
            width: '15%',
            display: row => row.status ? 'Успешно' : 'Не успешно'
          },
        ],
        messages: {
          pagingInfo: "Показываются {0}-{1} из {2}",
          pageSizeChangeLabel: "Всего страниц:",
          gotoPageLabel: "Перейти к странице:",
          noDataAvailable: "Нет данных",
        },
        rows: [],
        totalRecordCount: 0,
        sortable: {
          order: 'date',
          sort: 'asc',
        },
      },
    };
  },
  watch: {
    searchTerm(newSearch, oldSearch) {
      //let url = `http://89.22.235.121/api/log/?search=${newSearch}`;
      let url = `http://178.20.47.126/api/log/?search=${newSearch}`;
      axios.get(url)
      .then((response) => {

        this.table.rows = response.data.results;
        this.table.totalRecordCount = response.data.count;
      });
    }
  },
  mounted() {
    this.doSearch(0, 1, 'date', 'asc');
  },
};
</script>
