import axios from 'axios';
//const API_URL = 'http://89.22.235.121/api';
//const API_URL = 'http://178.20.47.126/api';
const API_URL = 'http://93.183.92.191/api';
class AuthService {
  login(user) {
    return axios
    .post(API_URL + '/check-account/', {
      login: user.login,
      password: user.password
    })
    .then(response => {
      let authString =  'Basic ' + btoa(user.login + ':' + user.password)
      localStorage.setItem('authString', authString);
      localStorage.setItem('is_superuser', response.data.is_superuser);
      localStorage.setItem('userRoles', JSON.stringify(response.data.user_permissions));
      return {
        user: authString,
        roles: response.data.user_permissions,
        isSuperUser: response.data.is_superuser
      };
    }).catch(error => {console.log(error);})
  }
  logout() {
    localStorage.removeItem('authString');
    localStorage.removeItem('userRoles');
  }
  getCurrentUserRoles() {
    return JSON.parse(localStorage.getItem('userRoles'))
  }
  getSuperUserState() {
    return JSON.parse(localStorage.getItem('is_superuser'))
  }
}
export default new AuthService();
