<template>
  <div class="users-table">
    <VTable class="numbers-table" :data="users">
      <template #head>
        <tr>
          <VTh sortKey="username" defaultSort="desc">Логин</VTh>
          <VTh sortKey="user_permissions">Разделы</VTh>
          <th>Удалить</th>
        </tr>
      </template>
      <template #body="{rows}">
        <tr v-for="row in rows" :key="row.id">
          <td>{{ row.username }}</td>
          <td>{{ parseRoles(row.user_permissions) }}</td>
          <td class="delete-button delete-number"><button type="button" @click="deleteNotice(row.id)">×</button></td>
        </tr>
      </template>
    </VTable>
  </div>
</template>

<script>
import {openModal} from "jenesius-vue-modal"
import WidgeTestModal from "../popups/UserDelete.vue";
import {default as axios} from '@/services/axios.service';
import {RoleNames} from '@/helpers/role';

export default {
  name: 'UsersTable',
  methods: {
    async deleteNotice(id) {
      const modal = await openModal(WidgeTestModal, {
        title: "Вы уверены, что хотите удалить пользователя?"
      })

      modal.on('submit', (event) => {
        event.preventDefault()
        axios.delete(`api/user/${id}/`)
        .then(() => {
          modal.close();
          this.users = this.users.filter(item => item.id !== id)
        })
      })
    },
    parseRoles(roles) {
      let roleStr = 'N/A'

      roles.forEach(roleId => {
        for (let object of Object.values(RoleNames)) {
          if (roleId == object.id) {
            roleStr = ''
            roleStr = roleStr.concat(object.name, ';')
          }
        }
      })

      return roleStr
    }
  },
  mounted() {
    axios.get('api/user/').then(response => {
      this.users = response.data
    })
  },
  data() {
    return {
      users: []
    }
  }
}
</script>
