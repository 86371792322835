<template>
  <div>
    <!-- Контент вашего компонента здесь -->
    <h1>Main Page Component</h1>
  </div>
</template>

<script>
export default {
  name: 'MainPageComponent',
};
</script>

<style scoped>

</style>
