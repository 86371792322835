import { createWebHistory, createRouter } from "vue-router";
import AddNumber from "@/views/AddNumber.vue";
import AllNumbers from "@/views/AllNumbers.vue";
import DeletedNumbers from "@/views/DeletedNumbers.vue";
import PaymentsTable from "@/views/PaymentsTable.vue"
import UsersTable from "@/views/UsersTable.vue"
import SettingsPage from "@/views/SettingsPage.vue"
import AddUser from "@/views/AddUser.vue"
import Login from '@/views/Login'
import LogsPage from '@/views/LogsPage';
import AuthService from '@/services/auth.service'
import { Role } from '@/helpers/role';
import {store} from '@/store/store';
import ErrorPage from '@/views/ErrorPage';
import MainPageComponent from '@/views/MainPageComponent';

const routes = [
    {
        path: '/',
        name: 'MainPageComponent',
        component: MainPageComponent,
        meta: { authorize: [] }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: "/add-number",
        name: "AddNumber",
        component: AddNumber,
        meta: { authorize: [Role.AddNumber] }
    },
    {
        path: "/numbers",
        name: "AllNumbers",
        component: AllNumbers,
        meta: { authorize: [Role.Numbers] }
    },
    {
        path: "/deleted-numbers",
        name: "DeletedNumbers",
        component: DeletedNumbers,
        meta: { authorize: [Role.DeletedNumbes] }
    },
    {
        path: "/payments",
        name: "PaymentsTable",
        component: PaymentsTable,
        meta: { authorize: [Role.Payments] }
    },
    {
        path: "/users",
        name: "UsersTable",
        component: UsersTable,
        meta: { authorize: [Role.AddUser] }
    },
    {
        path: "/settings",
        name: "SettingsPage",
        component: SettingsPage,
        meta: { authorize: [Role.Settings] }
    },
    {
        path: "/add-user",
        name: "AddUser",
        component: AddUser,
        meta: { authorize: [Role.AddUser] }
    },
    {
        path: '/logs',
        name: 'LogsPage',
        component: LogsPage,
        meta: { authorize: [Role.Null] }
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage,
        meta: { authorize: [] }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const { authorize } = to.meta;
    const currentUserRoles = AuthService.getCurrentUserRoles()
    const currentUserAdminState = AuthService.getSuperUserState()
    
    if (authorize) {
        if (!store.state.status.loggedIn) {
            return next({ path: '/login', query: { returnUrl: to.path } });
        }
    
        let checkRolesResult = authorize.find(function(item) {
            return currentUserRoles.includes(item.id)
        });
        
        if (currentUserAdminState) {
            next()
        }
        
        if (authorize.length && !checkRolesResult) {
            return next({ path: '/error' });
        }
    }
    
    next();
})


export default router;
