<template>
<div class="show-menu hidden" @click="showMenu">
  <span class="show">></span>
</div>
</template>

<script>
  export default {
    name: 'HideButton',
    methods: {
      showMenu(){
        document.querySelector('.menu').classList.remove('hidden')
        document.querySelector('.container').classList.remove('grid-block')
        document.querySelector('.show-menu').classList.add('hidden')
      }
    }
  }
</script>