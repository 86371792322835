<template>
  <div v-bind:class="{'menu-login': !loggedIn}" class="container">
    <widget-container-modal />
    <HideButton></HideButton>
    <MenuMTC></MenuMTC>
  </div>
</template>

<script>
import {container} from "jenesius-vue-modal";
import MenuMTC from './components/menu.vue'
import HideButton from './components/hide-button'

export default {
  name: 'App',
  components: {
    HideButton, MenuMTC, WidgetContainerModal: container
  },
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
