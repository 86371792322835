import { createStore } from 'vuex'
import AuthService from '../services/auth.service';

const authString = localStorage.getItem('authString');
const userRoles = JSON.parse(localStorage.getItem('userRoles'));
const isSuperUser = (localStorage.getItem('is_superuser') === 'true');
const initialState = authString
    ? { status: { loggedIn: true }, authString, userRoles, isSuperUser }
    : { status: { loggedIn: false }, authString: null, userRoles: null, isSuperUser: false };

export const store = createStore({
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(user => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            }).catch(error => {
                commit('loginFailure');
                return Promise.reject(error);
            });
        },
        logout({commit}) {
            commit('logout');
        }
    },
    getters: {
    
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.authString = user.user;
            state.userRoles = user.roles;
            state.isSuperUser = user.isSuperUser;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.authString = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.authString = null;
            state.userRoles = null;
            state.isSuperUser = false;
            localStorage.removeItem('authString')
            localStorage.removeItem('userRoles')
        },
    }
})
