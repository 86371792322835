<template>
<h1>Нет доступа</h1>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style scoped>
h1 {
  margin-top: 50px;
}
</style>
