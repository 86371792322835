<template>
  <div class="modal-back">
    <form action="#">
      <label>
        Введите код из СМС
        <input v-model="sms_code" type="text" required>
      </label>
      <button type="submit" class="red-button submit-sms">Подтвердить</button>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    code: String
  },
  data() {
    return {
      sms_code: null
    }
  }
}
</script>
