<template>
  <div class="numbers">
    <VTable class="numbers-table" :data="numbers">
      <template #head>
        <tr>
          <VTh :customSort="dateSort" defaultSort="desc">Дата добавления</VTh>
          <VTh sortKey="number">Номер</VTh>
          <VTh sortKey="forwarding">Переадресация</VTh>
          <VTh sortKey="balance">Баланс</VTh>
          <VTh sortKey="max_balance">MAX Баланс</VTh>
          <VTh sortKey="password">Пароль</VTh>
          <VTh sortKey="comment">Комментарий</VTh>
        </tr>
      </template>
      <template #body="{rows}">
        <tr v-for="row in rows" :key="row.id">
          <td>
            {{ formatDate(new Date(row.date)) }}
          </td>
          <td class="delete-button delete-number">
            {{ row.number }}
            <button class="red-button" @click="deleteNotice(row.id)">×</button>
          </td>
          <td class="flex-area">
            <textarea class="number-area" v-maska="['+7 (###) ###-##-##', '+7 (___) ___-__-__']"
                      v-model="row.forwarding" readonly></textarea>
            <button class="edit-button" @click="editElement()"><img class="edit-2" src="/svg/edit.svg" alt=""></button>
            <button class="red-button save-button hidden" @click="saveElement(row.id)">Сохранить</button>
          </td>
          <td>
            {{ row.balance }} ₽
          </td>
          <td class="flex-area">
            <textarea v-model="row.max_balance" class="number-area max-area" readonly></textarea>&nbsp;₽
            <button class="edit-button" @click="editElement()"><img class="edit-2" src="/svg/edit.svg" alt=""></button>
            <button class="red-button save-button hidden" @click="saveElement(row.id)">Сохранить</button>
          </td>
          <td>
            {{ row.password }}
          </td>
          <td class="flex-area">
            <textarea :value="row.comment" v-on:change="event => currentComment = event.target.value" class="number-area commentary-area" readonly></textarea>
            <button class="edit-button" @click="editElement(row.comment)"><img class="edit-2" src="/svg/edit.svg" alt=""></button>
            <button class="red-button save-button hidden" @click="saveElement(row.id)">Сохранить</button>
          </td>
        </tr>
      </template>
    </VTable>
  </div>
</template>

<script>
import {openModal} from 'jenesius-vue-modal';
import WidgeTestModal from '../popups/UserDelete.vue';
import {default as axios} from '@/services/axios.service';

export default {
  name: 'AllNumbers',
  data() {
    return {
      numbers: [],
      currentComment: null,
    };
  },
  computed: {
    test: {
      get(value) {
        return this.currentEditableComment ?? value
      }
    }
  },
    methods: {
      padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      },
      formatDate(date) {
        return (
            [
              date.getFullYear(),
              this.padTo2Digits(date.getMonth() + 1),
              this.padTo2Digits(date.getDate()),
            ].join('-') +
            ' ' +
            [
              this.padTo2Digits(date.getHours()),
              this.padTo2Digits(date.getMinutes()),
              this.padTo2Digits(date.getSeconds()),
            ].join(':')
        );
      },
      dateSort(a, b, sortOrder) {
        let date1 = new Date(a.date).getTime();
        let date2 = new Date(b.date).getTime();

        if (sortOrder === 1) {
          return date1 - date2;
        } else if (sortOrder === -1) {
          return date2 - date1;
        } else {
          return 0;
        }
      },
      editElement(val) {
        document.addEventListener('click', e => {
          const clickTarget = e.target;
          const clickParent = clickTarget.parentElement;
          const siblingEl = clickParent.previousElementSibling;
          const buttonEl = clickParent.nextElementSibling;

          if (e.target.classList.contains('edit-2')) {
            if (val) {
              this.currentComment = val
            }
            console.log()
            siblingEl.readOnly = false;
            buttonEl.classList.remove('hidden');
          }
        });
      },
      saveElement(id) {
        this.updateNumber(id);
        document.addEventListener('click', e => {
          const getButton = e.target;
          if (e.target.classList.contains('save-button')) {
            const getParent = getButton.parentElement;
            const childrenArea = getParent.children;

            childrenArea.item(0).readOnly = true;
            getButton.classList.add('hidden');
          }
          e.preventDefault();
        });
      },
      async deleteNotice(id) {
        const modal = await openModal(WidgeTestModal, {
          title: 'Вы уверены, что хотите удалить номер?',
        });
        modal.on('submit', v => {
          v.preventDefault();
          axios.delete(`api/number/${id}/`).then(response => {
            modal.close()
            this.$router.go(this.$router.currentRoute)
          })
        });
      },
      updateNumber(id) {
        let numberData = this.numbers.find(x => x.id === id);
        if (numberData.forwarding) {
          numberData.forwarding = numberData.forwarding.replace(/[^0-9]/g, '').
              substring(0, 11);
        }

        if (this.currentComment) {
          numberData.comment = this.currentComment
        }

        axios.patch(`api/number/${id}/`, numberData)
        .then(response => {
          this.currentComment = null
          console.log('success')
        })
        .catch(error => {
          this.currentComment = null
          console.log('error')
        })
      },
    },
    mounted() {
      axios.get('api/number/').then(response => {
        this.numbers = response.data;
      });
    },
  }
</script>
