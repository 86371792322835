import axios from 'axios';

const config = {
  'Accept': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

const axiosService = axios.create({
  headers: config,
//  baseURL: 'http://89.22.235.121/'
//  baseURL: 'http://178.20.47.126/'
  baseURL: 'http://93.183.92.191/'
});

axiosService.interceptors.request.use(
    (config) => {
      let authString = localStorage.getItem('authString');
  
      if (authString) {
        config.headers['Authorization'] = authString;
      }
      
      return config;
    },
    
    (error) => {
      return Promise.reject(error);
    }
);


export default axiosService;
